import React, { useEffect, useState } from 'react';
import { FaCoins, FaQuestionCircle } from 'react-icons/fa';
import { Tooltip } from '~/components/ui/Tooltip';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { HIGLAN_BLUE_01 } from '~/style/constants';

type TotalEarnedProps = {
  total: number;
  minValueForWithdraw: number;
};

export const TotalEarned: React.FC<TotalEarnedProps> = ({ total, minValueForWithdraw }) => {
  const [displayTotal, setDisplayTotal] = useState(0);

  useEffect(() => {
    // Animate the counter from 0 to the total
    let start = 0;
    const duration = 1000; // Animation duration in ms
    const increment = total / (duration / 50); // Update every 50ms

    const timer = setInterval(() => {
      start += increment;
      if (start >= total) {
        start = total;
        clearInterval(timer);
      }
      const parsedStart = parseFloat(start.toFixed(4));
      setDisplayTotal(parsedStart);
    }, 50);

    return () => clearInterval(timer);
  }, [total]);

  return (
    <div style={styles.container}>
      <FaCoins style={styles.icon} />
      <div style={styles.textContainer}>
        <span style={styles.label}>
          Total Earned
          {total < minValueForWithdraw && (
            <Tooltip
              content={`Withdraw is available when you reach at least ${displayPriceWithCurrency(minValueForWithdraw)}`}
            >
              <FaQuestionCircle style={styles.infoIcon} />
            </Tooltip>
          )}
        </span>
        <span style={styles.amount}>{displayPriceWithCurrency(displayTotal, 4)}</span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    width: '220px',
    transition: 'transform 0.3s',
  },
  icon: {
    fontSize: '2.5rem',
    marginRight: '15px',
    color: '#ffd700', // Gold color for the coin icon
  },
  textContainer: {
    textAlign: 'left' as const,
    position: 'relative' as const,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    color: '#777',
    marginBottom: '5px',
  },
  infoIcon: {
    fontSize: '1rem',
    color: '#777',
    marginLeft: '8px',
    cursor: 'pointer',
  },
  amount: {
    display: 'block',
    fontSize: '1.8rem',
    fontWeight: 'bold' as const,
    color: HIGLAN_BLUE_01,
  },
};
