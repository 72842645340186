import { getDayStreak } from '~/store/modules/faucet/selectors';
import { DailyProgressContainer, DailyProgressTitle, Info, Step, Steps } from './styles';
import { useSelector } from 'react-redux';

interface DailyProgress {
  progress: number;
}

export const TOTAL_CLAIMS = 5;

export const DailyProgress = ({ progress }: DailyProgress) => {
  const { total: totalDayStreak } = useSelector(getDayStreak);

  const renderStreakBonusText = () => {
    if (totalDayStreak === 30) {
      if (progress < 5) {
        return (
          <>
            Maintain your <strong>streak's bonus</strong>:
          </>
        );
      }
      return (
        <>
          You've maintained your <strong>streak's bonus</strong> today!
        </>
      );
    }

    if (progress >= 5) {
      return (
        <>
          You've increased your <strong>streak's bonus</strong> today!
        </>
      );
    }

    return (
      <>
        Increase your <strong>streak's bonus</strong>:
      </>
    );
  };

  return (
    <DailyProgressContainer>
      <DailyProgressTitle>Daily Progress</DailyProgressTitle>
      <Info>{renderStreakBonusText()}</Info>
      <Steps>
        {Array.from({ length: TOTAL_CLAIMS }, (_, index) => {
          const isCompleted = index < progress;
          return (
            <Step key={index} isCompleted={isCompleted}>
              {isCompleted ? '✅' : index + 1}
            </Step>
          );
        })}
      </Steps>
      <Info>
        {progress} / {TOTAL_CLAIMS} claims completed today
      </Info>
    </DailyProgressContainer>
  );
};
