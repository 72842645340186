import { FaGift } from 'react-icons/fa6';
import styled from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT, DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const CaptchaContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 17px;
`;

export const CaptchaText = styled.div`
  font-size: 1rem;
`;

export const CountdownContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT};
  gap: 7px;
`;

export const TimeLeftText = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const BonusClaimContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px auto;
  width: 100%;
`;

export const BonusClaimHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const BonusClaimTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin: 0;
`;

export const StyledFaGift = styled(FaGift)`
  font-size: 2rem;
  color: #f39c12;
  margin-right: 10px;
`;

export const Info = styled.div`
  font-size: 1rem;
  margin: 10px 0;
`;

export const BonusesContainer = styled.div`
  background-color: #f0f8ff;
  margin: 15px auto;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  color: #333;
  gap: 10px;
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
`;

export const BonusRow = styled.div`
  ${DISPLAY_FLEX_LINE_DEFAULT}
  justify-content: space-between;
  width: 100%;
`;
