import { authActionTypes } from '../auth/actions';
import { faucetActionTypes, IFaucetAction } from './actions';

const INITIAL_STATE = {
  apiLoading: false,
  withdrawLoading: false,
  timeForNextRound: 0,
  allTimeEarned: 0,
  faucetBaseValue: 0,
  faucetBalance: 0,
  bonusPerStreakDay: 0,
  faucetHistory: [],
  dailyProgress: {
    isDailyProgressCompleted: false,
    faucetHistoryTodayTotal: 0,
  },
  dayStreak: {
    total: 0,
    isCompleted: false,
  },
  shouldPlayFaucetSounds: true,
};

export const faucet = (state = INITIAL_STATE, action: IFaucetAction) => {
  const { type, payload } = action;

  switch (type) {
    case faucetActionTypes.START_LOADING: {
      return {
        ...state,
        apiLoading: true,
      };
    }
    case faucetActionTypes.STOP_LOADING: {
      return {
        ...state,
        apiLoading: false,
      };
    }
    case faucetActionTypes.SET_TIME_FOR_NEXT_ROUND: {
      return {
        ...state,
        timeForNextRound: payload,
      };
    }
    case faucetActionTypes.SET_FAUCET_BASE_VALUE: {
      return {
        ...state,
        faucetBaseValue: payload,
      };
    }
    case faucetActionTypes.SET_ALL_TIME_EARNED: {
      return {
        ...state,
        allTimeEarned: payload,
      };
    }
    case faucetActionTypes.SET_FAUCET_BALANCE: {
      return {
        ...state,
        faucetBalance: payload,
      };
    }
    case faucetActionTypes.SET_FAUCET_HISTORY: {
      return {
        ...state,
        faucetHistory: payload,
      };
    }
    case faucetActionTypes.SET_MIN_VALUE_FOR_WITHDRAW: {
      return {
        ...state,
        minValueForWithdraw: payload,
      };
    }
    case faucetActionTypes.TOGGLE_WITHDRAW_LOADING: {
      return {
        ...state,
        withdrawLoading: !state.withdrawLoading,
      };
    }
    case faucetActionTypes.SET_FAUCET_STREAK_DATA: {
      if (typeof payload === 'object' && payload !== null) {
        if ('dailyProgress' in payload && 'dayStreak' in payload) {
          return {
            ...state,
            dailyProgress: payload.dailyProgress,
            dayStreak: payload.dayStreak,
          };
        }
        console.error('Invalid object structure for SET_FAUCET_STREAK_DATA payload');
      }
      return state;
    }
    case faucetActionTypes.SET_SHOULD_PLAY_FAUCET_SOUNDS: {
      return {
        ...state,
        shouldPlayFaucetSounds: payload,
      };
    }
    case faucetActionTypes.SET_FAUCET_BONUS_PER_STREAK_DAY: {
      return {
        ...state,
        bonusPerStreakDay: payload,
      };
    }
    case authActionTypes.SIGN_OUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    default: {
      return state;
    }
  }
};
