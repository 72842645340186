import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { REDUCER_KEY } from 'style/constants';

export default (reducers: any) => {
  const persitedReducer = persistReducer(
    {
      key: REDUCER_KEY,
      storage,
      whitelist: ['auth', 'user', 'faucet'],
    },
    reducers,
  );

  return persitedReducer;
};
