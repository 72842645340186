import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Container } from '../_layouts/default/styles';
import {
  BonusText,
  FaucetContainer,
  FaucetDescription,
  FaucetTitle,
  FreeBonusContainer,
  FreeBonusHeader,
  StreakContainer,
} from './styles';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { FaFaucet } from 'react-icons/fa';
import { MdOutlineHistory } from 'react-icons/md';
import {
  getFaucetBalance,
  getFaucetHistory,
  getMinValueForWithdraw,
} from '~/store/modules/faucet/selectors';
import { HistoryModal } from './HistoryModal';
import { WithdrawModal } from './WithdrawModal';
import { StreakTracker } from './StreakTracker';
import { TotalEarned } from './TotalEarned';
import { BonusClaim } from './BonusClaim';

export function Faucet() {
  const [shouldShowHistoryModal, setShouldShowHistoryModal] = useState(false);
  const [shouldShowWithdrawModal, setShouldShowWithdrawModal] = useState(false);
  const faucetBalance = useSelector(getFaucetBalance);
  const faucetHistory = useSelector(getFaucetHistory);
  const minValueForWithdraw = useSelector(getMinValueForWithdraw);

  const handleToggleHistoryModal = () => {
    setShouldShowHistoryModal(!shouldShowHistoryModal);
  };

  const handleToggleWithdrawModal = () => {
    setShouldShowWithdrawModal(!shouldShowWithdrawModal);
  };

  return (
    <Container>
      <FaucetContainer>
        <FaucetTitle>
          Higlan Faucet <FaFaucet />
        </FaucetTitle>
        <FaucetDescription>
          Earn Free <BonusText>Bonus Credit</BonusText>. Increase it by playing the faucet at least <strong>5</strong>{' '}
          times a day.
        </FaucetDescription>
        <FreeBonusContainer>
          <FreeBonusHeader>
            <TotalEarned total={faucetBalance} minValueForWithdraw={minValueForWithdraw} />
            {faucetBalance >= minValueForWithdraw && (
              <BootstrapButton size="sm" variant="success" onClick={handleToggleWithdrawModal}>
                WITHDRAW
              </BootstrapButton>
            )}
            <BootstrapButton variant="info" color="white" onClick={handleToggleHistoryModal}>
              HISTORY
              <MdOutlineHistory />
            </BootstrapButton>
          </FreeBonusHeader>
          <StreakContainer>
            <StreakTracker />
          </StreakContainer>
          <BonusClaim />
        </FreeBonusContainer>
      </FaucetContainer>
      {shouldShowHistoryModal && <HistoryModal onHideModal={handleToggleHistoryModal} faucetHistory={faucetHistory} />}
      {shouldShowWithdrawModal && (
        <WithdrawModal onHideModal={handleToggleWithdrawModal} faucetBalance={faucetBalance} />
      )}
    </Container>
  );
}
