import { Tooltip } from '~/components/ui/Tooltip';
import { DayBox, Info, StreakDaysContainer, StreakTrackerContainer, StreakTrackerTitle } from './styles';
import { useSelector } from 'react-redux';
import { getBonusPerStreakDay, getDayStreak } from '~/store/modules/faucet/selectors';
import { TOTAL_CLAIMS } from '../DailyProgress';
import { displayPriceWithCurrency } from '~/shared/string-utils';
import { BonusText } from '../styles';

export const StreakTracker = () => {
  const totalDays = 30;
  const dayStreak = useSelector(getDayStreak);
  const bonusPerStreakDay = useSelector(getBonusPerStreakDay);
  const { total: totalDayStreak } = dayStreak;

  return (
    <StreakTrackerContainer>
      <StreakTrackerTitle>30-Day Streak</StreakTrackerTitle>
      <Info>
        {`Play the faucet at least `}
        <strong>{TOTAL_CLAIMS} times</strong>
        {` every day to maintain your streak.`}
      </Info>
      <Info>
        <BonusText>+{displayPriceWithCurrency(bonusPerStreakDay, 4)}</BonusText>
        {` every day.`}
      </Info>
      <StreakDaysContainer>
        {Array.from({ length: totalDays }, (_, index) => {
          const isCompleted = index < totalDayStreak;
          const day = index + 1;
          const totalIncreasedPerStreakDay = bonusPerStreakDay * day;

          return (
            <Tooltip
              content={`Day ${day}: +${displayPriceWithCurrency(totalIncreasedPerStreakDay, 4)} per claim`}
              key={index}
            >
              <DayBox isCompleted={isCompleted}>{isCompleted ? '🔥' : day}</DayBox>
            </Tooltip>
          );
        })}
      </StreakDaysContainer>
      <Info>
        {totalDayStreak} / {totalDays} days completed
      </Info>
    </StreakTrackerContainer>
  );
};
