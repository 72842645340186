import api from 'services/api';
import { IDailyProgress, IDayStreak, IFaucetHistory } from '../interfaces';

export const sendFaucetCaptchaRequest = async (captchaToken: string) => {
  try {
    const { data } = await api.post('faucet', { captchaToken });

    const { timeForNextRound, faucetHistoryTodayTotal, totalDayStreak } = data;

    return { timeForNextRound, faucetHistoryTodayTotal, totalDayStreak };
  } catch (err) {
    console.error('Something went wrong on sendFaucetCaptchaRequest.');
    throw err;
  }
};

export const getFaucetValuesRequest = async (): Promise<{
  faucetBaseValue: number;
  bonusPerStreakDay: number;
  faucetBalance: number;
  timeForNextRound: number;
  allTimeEarned: number;
  faucetHistory: IFaucetHistory[];
  minValueForWithdraw: number;
  dailyProgress: IDailyProgress;
  dayStreak: IDayStreak;
}> => {
  try {
    const { data } = await api.get('faucet');
    return data;
  } catch (err) {
    console.error('Something went wrong on getFaucetBaseValueRequest.');
    throw err;
  }
};

export const withdrawRequest = async () => {
  try {
    await api.post('faucet/withdraw');
  } catch (err) {
    console.error('Something went wrong on withdrawRequest.');
    throw err;
  }
};

export const toggleFaucetSoundsRequest = async () => {
  try {
    const { data } = await api.put('faucet/sounds');
    return data;
  } catch (err) {
    console.error('Something went wrong on toggleFaucetSoundsRequest.');
    throw err;
  }
};
