import styled, { css } from 'styled-components/macro';

const COMPLETED = css`
  background-color: #4caf50;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const NOT_COMPLETED = css`
  background-color: #e0e0e0;
  color: #757575;
`;

export const StreakTrackerContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const StreakTrackerTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
`;

export const Info = styled.div`
  margin-top: 20px;
  font: 0.9rem;
  color: #555;
`;

export const DayBox = styled.div<{ isCompleted: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;

  ${({ isCompleted }) => (isCompleted ? COMPLETED : NOT_COMPLETED)}
`;

export const StreakDaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
