import { toast } from 'react-toastify';
import { takeLatest, all, call, put, select } from 'redux-saga/effects';

import { pushLoadingAppDataRequest, spliceLoadingAppDataRequest } from '../app/actions';
import { IDailyProgress, IDayStreak, IFaucetHistory } from '../interfaces';
import claim01 from '~/assets/sounds/claim01.wav';
import streakDay from '~/assets/sounds/streak_day_mark.wav';

import {
  faucetActionTypes,
  ISendFaucetCaptcha,
  IWithdraw,
  setAllTimeEarned,
  setFaucetBalance,
  setFaucetBaseValue,
  setFaucetBonusPerStreakDay,
  setFaucetHistory,
  setFaucetStreakData,
  setMinValueForWithdraw,
  setShouldPlayFaucetSounds,
  setTimeForNextRound,
  startFaucetApiLoading,
  stopFaucetApiLoading,
  toggleWithdrawLoading,
} from './actions';
import { sendFaucetCaptchaRequest, getFaucetValuesRequest, withdrawRequest, toggleFaucetSoundsRequest } from './api';
import { timeToWait } from '~/shared/string-utils';
import { getShouldPlayFaucetSounds } from './selectors';

function playSound(audioUrl: string) {
  const audio = new Audio(audioUrl);
  audio.play();
}

function* sendFaucetCaptchaSaga({ payload: captchaToken }: ISendFaucetCaptcha) {
  try {
    yield put(startFaucetApiLoading());
    const { timeForNextRound, faucetHistoryTodayTotal, totalDayStreak } = yield call(
      sendFaucetCaptchaRequest,
      captchaToken
    );
    const isSoundEnabled: boolean = yield select(getShouldPlayFaucetSounds);
    if (isSoundEnabled) {
      playSound(claim01);
    }
    yield put(setTimeForNextRound(timeForNextRound));
    toast.success('Successfully claimed!');
    if (isSoundEnabled && faucetHistoryTodayTotal === 5 && totalDayStreak <= 30) {
      yield timeToWait(0.3);
      playSound(streakDay);
    }
  } catch (err) {
    yield put(setTimeForNextRound(0));
    const response: any = { err };

    const error = response.err.response?.data?.error;

    toast.error(error);
  } finally {
    yield put(stopFaucetApiLoading());
  }
}

function* getFaucetBaseValueSaga() {
  const requestName = 'getFaucetBaseValueSaga';
  try {
    yield put(pushLoadingAppDataRequest(requestName));
    const faucetValues: {
      faucetBaseValue: number;
      bonusPerStreakDay: number;
      faucetBalance: number;
      timeForNextRound: number;
      allTimeEarned: number;
      faucetHistory: IFaucetHistory[];
      minValueForWithdraw: number;
      dailyProgress: IDailyProgress;
      dayStreak: IDayStreak;
    } = yield call(getFaucetValuesRequest);
    const {
      faucetBalance,
      faucetBaseValue,
      timeForNextRound,
      faucetHistory,
      minValueForWithdraw,
      allTimeEarned,
      dailyProgress,
      dayStreak,
      bonusPerStreakDay,
    } = faucetValues;
    yield put(setFaucetBaseValue(faucetBaseValue));
    yield put(setFaucetBonusPerStreakDay(bonusPerStreakDay));
    yield put(setFaucetBalance(faucetBalance));
    yield put(setFaucetHistory(faucetHistory));
    yield put(setAllTimeEarned(allTimeEarned));
    yield put(setMinValueForWithdraw(minValueForWithdraw));
    yield put(
      setFaucetStreakData({
        dailyProgress,
        dayStreak,
      })
    );
    const timeForNextRoundTimestamp = new Date(timeForNextRound).getTime();
    yield put(setTimeForNextRound(timeForNextRoundTimestamp));
  } catch (err) {
  } finally {
    yield put(spliceLoadingAppDataRequest(requestName));
  }
}

function* withdrawSaga({ payload }: IWithdraw) {
  const { onHideModal } = payload;
  try {
    yield put(toggleWithdrawLoading());
    yield call(withdrawRequest);
    toast.success('Successfully executed the withdraw!');
    onHideModal();
  } catch (err) {
    const response: any = { err };

    const error = response.err.response?.data?.error;

    toast.error(error);
  } finally {
    yield put(toggleWithdrawLoading());
  }
}

function* toggleFaucetSoundsSaga() {
  try {
    const { should_play_faucet_sounds } = yield call(toggleFaucetSoundsRequest);
    if (should_play_faucet_sounds) {
      playSound(claim01);
    }
    const message = should_play_faucet_sounds ? `Sounds effects are now enabled.` : `Sounds effects are now disabled.`;
    yield put(setShouldPlayFaucetSounds(should_play_faucet_sounds));
    toast.success(message);
  } catch (err) {
    const response: any = { err };

    const error = response.err.response?.data?.error;

    toast.error(error);
  } finally {
    yield put(toggleWithdrawLoading());
  }
}

export default all([
  takeLatest(faucetActionTypes.SEND_FAUCET_CAPTCHA, sendFaucetCaptchaSaga),
  takeLatest(faucetActionTypes.GET_FAUCET_VALUES, getFaucetBaseValueSaga),
  takeLatest(faucetActionTypes.WITHDRAW, withdrawSaga),
  takeLatest(faucetActionTypes.TOGGLE_FAUCET_SOUNDS, toggleFaucetSoundsSaga),
]);
