import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { IconWrapper, SoundToggleContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFaucetSounds } from '~/store/modules/faucet/actions';
import { getShouldPlayFaucetSounds } from '~/store/modules/faucet/selectors';

export const SoundToggle = () => {
  const dispatch = useDispatch();
  const shouldPlayFaucetSounds = useSelector(getShouldPlayFaucetSounds);

  const handleToggle = () => {
    dispatch(toggleFaucetSounds());
  };

  return (
    <SoundToggleContainer onClick={handleToggle}>
      <IconWrapper>{shouldPlayFaucetSounds ? <FaVolumeUp /> : <FaVolumeMute />}</IconWrapper>
    </SoundToggleContainer>
  );
};
