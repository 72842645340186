import { IState } from 'store/modules/interfaces';

export const isLoadingApi = ({ faucet }: IState) => faucet.apiLoading;

export const getTimeForNextRound = ({ faucet }: IState) => {
  const { timeForNextRound } = faucet;
  const nextFaucetTime = timeForNextRound - Date.now();
  return nextFaucetTime;
};

export const getFaucetBaseValue = ({ faucet }: IState) => faucet.faucetBaseValue;

export const getFaucetBalance = ({ faucet }: IState) => faucet.faucetBalance;

export const getFaucetHistory = ({ faucet }: IState) => faucet.faucetHistory;

export const getMinValueForWithdraw = ({ faucet }: IState) => faucet.minValueForWithdraw;

export const getAllTimeEarned = ({ faucet }: IState) => faucet.allTimeEarned;

export const getWithdrawLoading = ({ faucet }: IState) => faucet.withdrawLoading;

export const getDailyProgress = ({ faucet }: IState) => faucet.dailyProgress;

export const getDayStreak = ({ faucet }: IState) => faucet.dayStreak;

export const getBonusPerStreakDay = ({ faucet }: IState) => faucet.bonusPerStreakDay;

export const getShouldPlayFaucetSounds = ({ faucet }: IState) => faucet.shouldPlayFaucetSounds;
