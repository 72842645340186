import styled from 'styled-components/macro';

import {
  DISPLAY_FLEX_COLUMN_DEFAULT,
  DISPLAY_FLEX_LINE_DEFAULT,
  HIGLAN_BLUE_01,
  HIGLAN_PURPLE_01,
  HIGLAN_PURPLE_02,
} from '~/style/constants';
import { mobileOnly } from '~/style/mixins';


export const FaucetContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 5px;
  text-align: center;
  padding: 5px;
`;

export const FaucetTitle = styled.div`
  font-size: 30px;
  color: ${HIGLAN_PURPLE_01};
  ${DISPLAY_FLEX_LINE_DEFAULT};
  gap: 10px;
`;

export const FaucetDescription = styled.div`
  margin-top: 30px;
  font-size: 18px;
`;

export const FreeBonusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 50px;
`;

export const FreeBonusContainer = styled.div`
  border: 1px solid ${HIGLAN_PURPLE_02};
  border-radius: 12px;
  padding: 20px; /* Increased padding for better spacing */
  margin-top: 20px; /* Slightly more margin for breathing room */
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  justify-content: space-between;
  background-color: #f9f9f9; /* Soft background for better contrast */
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

  ${mobileOnly`
    width: 100%; /* Full-width on mobile for responsiveness */
    padding: 15px; /* Adjust padding for smaller screens */
  `}
`;

export const StreakContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  align-items: flex-start;
  gap: 50px;
  margin-top: 20px;
`;

export const BonusText = styled.span`
  color: ${HIGLAN_BLUE_01};
  font-size: '1.2rem';
  font-weight: bold;
`;
