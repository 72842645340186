import styled from 'styled-components/macro';
import { DISPLAY_FLEX_LINE_DEFAULT } from '~/style/constants';

export const SoundToggleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #555;
  margin-top: 5px;
  margin-right: 5px;
  background: #f0f0f0;
  border-radius: 8px;
  padding: 8px 12px;
  transition: background-color 0.3s ease, color 0.3s ease;
  ${DISPLAY_FLEX_LINE_DEFAULT}

  &:hover {
    background: #e0e0e0;
    color: #000;
  }
`;

export const IconWrapper = styled.div`
  font-size: 20px;
`;