import styled, { css } from 'styled-components/macro';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';

const COMPLETED_STEP = css`
  background-color: #4caf50;
  color: #fff;
  border-color: #4caf50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const NOT_COMPLETED_STEP = css`
  background-color: #e0e0e0;
  color: #757575;
`;

export const DailyProgressContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 15px;
`;

export const DailyProgressTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
`;

export const Info = styled.div`
  font-size: 0.9rem;
  color: #555;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Step = styled.div<{ isCompleted: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50%;
  border: 2px solid #ccc;

  ${({ isCompleted }) => (isCompleted ? COMPLETED_STEP : NOT_COMPLETED_STEP)}
`;
