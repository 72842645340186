import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import {
  getBonusPerStreakDay,
  getDailyProgress,
  getDayStreak,
  getFaucetBaseValue,
  getTimeForNextRound,
  isLoadingApi,
} from '~/store/modules/faucet/selectors';
import { Countdown } from '~/components/Countdown';
import {
  BonusClaimContainer,
  BonusClaimHeader,
  BonusClaimTitle,
  BonusesContainer,
  BonusRow,
  CaptchaContainer,
  CaptchaText,
  CountdownContainer,
  Info,
  StyledFaGift,
  TimeLeftText,
} from './styles';
import HCaptchaConfig from '~/config/HCaptcha';
import { BonusText } from '../styles';
import { toast } from 'react-toastify';
import { sendFaucetCaptcha } from '~/store/modules/faucet/actions';
import { BootstrapButton } from '~/components/ui/BootstrapButton';
import { HIGLAN_BLUE_01 } from '~/style/constants';
import { GiReceiveMoney } from 'react-icons/gi';
import { DailyProgress } from '../DailyProgress';
import { SoundToggle } from '../SoundToggle';
import { TextRender } from '~/components/ui/TextRender';
import { displayPriceWithCurrency } from '~/shared/string-utils';

export const BonusClaim = () => {
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const captchaRef = useRef(null);
  const isLoading = useSelector(isLoadingApi);
  const timeForNextRound = useSelector(getTimeForNextRound);
  const { faucetHistoryTodayTotal } = useSelector(getDailyProgress);
  const { total: totalDayStreak } = useSelector(getDayStreak);
  const bonusPerStreakDay = useSelector(getBonusPerStreakDay);
  const faucetBaseValue = useSelector(getFaucetBaseValue);
  const streakBonus = bonusPerStreakDay * totalDayStreak;
  const nextAmountToGet = streakBonus + faucetBaseValue;

  const onHCaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  const dispatch = useDispatch();

  const resetCaptcha = () => {
    // @ts-ignore
    captchaRef.current?.resetCaptcha();
    setCaptchaToken('');
  };

  const handleSubmit = async () => {
    if (!captchaToken) {
      toast.error(`Please resolve the captcha.`, {
        pauseOnFocusLoss: false,
      });
      return;
    }
    dispatch(sendFaucetCaptcha(captchaToken));
    try {
    } catch (err) {
      const error: any = { err };
      const errorMessage = error.err.response.data.error;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      resetCaptcha();
    }
  }, [isLoading]);

  return (
    <BonusClaimContainer>
      <SoundToggle />
      <BonusClaimHeader>
        <StyledFaGift />
        <BonusClaimTitle>Free Bonus</BonusClaimTitle>
      </BonusClaimHeader>
      <DailyProgress progress={faucetHistoryTodayTotal} />
      <BonusesContainer>
        <BonusRow>
          <TextRender>Base Amount:</TextRender>
          <BonusText>{displayPriceWithCurrency(faucetBaseValue, 4)}</BonusText>
        </BonusRow>
        <BonusRow>
          <TextRender>Streak's Bonus:</TextRender>
          <BonusText>+{displayPriceWithCurrency(streakBonus, 4)}</BonusText>
        </BonusRow>
      </BonusesContainer>
      <Info>
        You can get in the next round: <BonusText>{displayPriceWithCurrency(nextAmountToGet, 4)}</BonusText>
      </Info>
      {timeForNextRound <= 0 ? (
        <CaptchaContainer>
          <CaptchaText>
            Solve the captcha bellow to claim your free <BonusText>Bonus Credit</BonusText>
          </CaptchaText>
          <HCaptcha ref={captchaRef} size="normal" sitekey={HCaptchaConfig.siteKey} onVerify={onHCaptchaVerify} />
          <BootstrapButton variant="success" onClick={handleSubmit} disabled={isLoading}>
            Solve Captcha
            <GiReceiveMoney />
          </BootstrapButton>
        </CaptchaContainer>
      ) : (
        <CountdownContainer>
          <TimeLeftText>Time to wait for the next round:</TimeLeftText>
          <Countdown timestamp={timeForNextRound} color={HIGLAN_BLUE_01} />
        </CountdownContainer>
      )}
    </BonusClaimContainer>
  );
};
